/*
	Theme Name: Bofilltech Framework
	Theme URI: http://domain.local
	Description: A child theme solution for your Genesis-powered site.
	Author: Carlos G.
	Author URI: http://domain.local

	Version: 1.0.0

	Tags: one-column, two-columns, left-sidebar, right-sidebar, accessibility-ready, custom-background, custom-colors, custom-header, custom-menu, e-commerce, featured-images, flexible-header, footer-widgets, full-width-template, sticky-post, theme-options, threaded-comments, translation-ready

	Template: genesis
	Template Version: 1.0.0

	License: GPL-2.0+
	License URI: http://www.gnu.org/licenses/gpl-2.0.html

*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

* {
  box-sizing: border-box;
}

$domain : '';
$theme-folder : 'bofilltech-framework';
$font-path : $domain+'/wp-content/themes/'+$theme-folder+'/fonts/';
$image-path : $domain+'/wp-content/themes/'+$theme-folder+'/images/';

@font-face {
  font-family: 'Adobe Caslon Pro';
  src: url($font-path + 'ACaslonPro-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src: url($font-path + 'HelveticaNowDisplay-ExtLt.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src: url($font-path + 'HelveticaNowDisplay-Thin.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue LT 35';
  src: url($font-path + 'HelveticaNeueLT-Thin.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html {
  overflow-x: hidden;
}

body {
  font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

.wrap {
  max-width: 1230px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}


em,
i {
  font-family: 'Adobe Caslon Pro';
  font-style: italic;
  font-weight: 400;
  line-height: 1;
}


/**************************************************/
/***************       Header      ****************/

header.site-header {
  padding: 12px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  transition: all ease 500ms;
  height: 100px;


  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    pointer-events: none;
    transition: all .4s ease;
    z-index: -1;
  }

  >.wrap {
    display: flex;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }

  .left-navigation {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: left;
    @media (max-width:599px) {
      width: 20%;
    }

    .nav-holder {
      padding-left: 50px;
    }

    .wrap {
      width: 100%;
      padding: 0;
    }

    nav {
      display: flex;
      align-items: center;

      ul {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        gap: 0 12px;

        li {
          list-style: none;

          a {
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            color: #fff;
            display: inline-block;
            padding: 5px 0;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.10em;

            &:hover {
              color: #031f2d;
            }
          }

          &.current-menu-item {
            a {
              font-weight: 400;
            }
          }

          &.btn-nav {
            a {
              padding: 8px 15px;
              text-decoration: none;
              text-transform: uppercase;
              color: #fff;
              border: 1px solid #fff;
              background: transparent;
              border-radius: 0;
              margin: 0;
              font-size: 16px;

              img {
                max-width: 15px;
                margin-right: 5px;
                object-fit: contain;
              }

              &:hover {
                background: #031f2d;
                color: #fff;
                border-color: #031f2d;

                img {
                  filter: hue-rotate(-0.25turn);
                }
              }

              span {
                font-size: 15px;
                display: flex;
                align-items: center;
                align-content: center;
              }
            }
          }

        }
      }
    }

    .sidebar-menu {
      background: rgba(255, 255, 255, 0.95);
      position: fixed;
      padding: 75px 0 30px;
      top: 0;
      left: 0;
      border-right: 2px solid #01273a;
      z-index: 1;
      box-shadow: 0 0 15px rgba(55, 55, 55, .3);
      transform: translate(-100%, 0);
      transition: transform ease 250ms;
      height: 100vh;

      >button {
        margin: 0;
        display: inline-block;
        text-align: center;
        background: transparent;
        border: 1px solid #ababab;
        color: #ababab;
        appearance: none;
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 5px 15px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 11px;

        cursor: pointer;

        &:hover {
          border: 1px solid #000;
          color: #000;
        }
      }

      &.display-active {
        transform: translate(0, 0);
        transition: transform ease 250ms;
      }

      nav {
        ul {
          display: flex;
          flex-direction: column;
          min-width: 250px;

          li {
            position: relative;
            list-style: none;
            margin-bottom: 5px;
            line-height: 2;
            padding-left: 18px;
            padding: 5px 40px 5px 40px;
            border-radius: 5px;
            width: 100%;

            &:last-child {
              border-bottom: none;
            }


            a {
              text-decoration: none;
              color: #01273a;
              transition: all ease 250ms;
              transform: translate(0, 0);
              display: block;
              letter-spacing: 1px;
              font-size: 60px;
              font-family: "Helvetica Now Display", Tahoma, Geneva, Verdana, sans-serif;
              font-weight: 300;
              text-transform: uppercase;
              line-height: 1.2;

              img {
                display: none;
              }
            }

            &:hover {
              background-color: #f2f2f2;
              text-decoration: none;

              a {
                transform: translate(8px, 0);
                transition: all ease 250ms;
                display: block;
                text-decoration: none !important;
                color: #01273a !important;
              }

              &:before {
                border-left: 10px solid #066fa4;
              }
            }
          }
        }
      }

      .bottom-logo {
        position: absolute;
        bottom: 30px;
        padding: 0 30px;
        width: 100%;

        img {
          display: block;
          height: auto;
          max-width: 100px;
          margin: 0 auto;
          filter: invert(100%);
        }
      }
    }

    #open-menu {
      background: transparent;
      appearance: none;
      border: none;
      border-radius: 3px;
      padding: 5px 5px;
      position: absolute;
      top: 27px;
      left: 15px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        max-width: 40px;
        height: auto;
        margin: 0 10px 0 0;
        filter: invert(100%);
        transition: all ease-out 0.3s;

        &:hover {
          transform: scale(1.05);
          transition: all ease 0.3s;
          filter: invert(0%);
        }
      }


    }
  }

  .logoContainer {
    position: relative;
    width: 20%;
    height: auto;

    img {
      display: block;
      object-fit: contain;
      object-position: left center;
      transform: scale(1);
      transition: transform ease 500ms;
      max-height: 75px;
      margin: 0 auto;
      filter: brightness(100%);

      &:hover {
        transform: scale(1.05);
        transition: all ease 250ms;
      }
    }
  }




  .lang-button-area {

    .lang-buttons {
      position: relative;
      padding-left: 20px;
      .button-toggle {
        padding: 8px 25px;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        border: 1px solid #fff;
        background: transparent;
        border-radius: 0px;
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        transition: 0.4s;

        @media (max-width: 800px) {
          padding: 5px 12px !important;
    top: 10px;
    position: relative;
        }

        @media (max-width:413px) {
          top: 0px;
          display: inline-block;
          padding: 4px 8px !important;
          font-size: 12px;
          width: 120px;
          text-align: center;
        }

        // border-radius: 18px;
        &:hover {
          color: white;
          background-color: #031f2d;
          border: 1px solid #031f2d;
        }

        @media (min-width: 480px) {
          font-size: 16px;
          min-height: 28px;
        }
      }

      .lang-button-group {
        position: absolute;
        top: calc(100% + 10px);
        visibility: hidden;
        opacity: 0;
        transition: 0.4s;
        width: calc(100% + 50px);
        left: -50px;
        z-index: 999;
        background-color: white;


        li {
          display: block;
          list-style: none;
          a {
            color: inherit;
            padding: 7px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            box-shadow: 0 4px 20px rgba(#000, 0.2);

            &:hover {
              background-color: #031f2d;
              color: white;
            }

            &.gt-current-lang {
              font-weight: 700;
            }
          }
        }

        &.open {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .lang-swtch-area {
    .gtranslate_wrapper {
      padding: 10px 20px;
      font-size: 14px;
      background-color: #6e8a7c !important;
      border-radius: px;
      margin-left: 20px;

      .gt_selector {
        padding: 0;
        background-color: transparent;
        color: #fff;
        border: none;
      }
    }

    img {
      display: none;
    }

    a {
      align-items: center;
      background-color: transparent;
      box-sizing: border-box;
      text-transform: uppercase;
      word-break: break-word;

      padding: 15px 4px;
      font-size: 12px;
      transition: 0.4s;
      letter-spacing: 1px;

      @media (min-width: 1340px) {
        padding: 15px 10px;
        font-size: 15px;
      }

      @media (min-width: 1440px) {
        padding: 15px 5px;
        font-size: 15px;
      }

      @media (min-width: 1600px) {
        padding: 15px 15px;
        font-size: 16px;
      }

      @media (min-width: 1800px) {
        padding: 15px 15px;
        font-size: 18px;
      }

      &:focus,
      &:hover {
        color: green;
      }
    }
  }

  .right-navigation {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: right;

    @media (max-width:599px) {
      width: 60%;
    }
    @media (max-width:413px) {
      width: 60%;
      display: grid;

    }

    .wrap {
      width: 100%;
      padding: 0;
    }

    nav {
      display: flex;
      align-items: center;

      ul {
        width: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 0 12px;

        li {
          list-style: none;

          a {
            position: relative;
            text-decoration: none;
            white-space: nowrap;
            color: #02293a;
            display: inline-block;
            padding: 5px 0;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.10em;

            &:hover {
              color: #c3c3c3;
            }
          }

          &.current-menu-item {
            a {
              font-weight: bold;
            }
          }

          &.btn-nav {
            a {
              padding: 8px 25px;
              text-decoration: none;
              text-transform: uppercase;
              color: #fff;
              border: 1px solid #fff;
              background: transparent;
              border-radius: 0px;
              margin: 0;
              font-weight: 400;
              font-size: 16px;

              @media (max-width:413px) {
                top: 0px;
                display: inline-block;
                padding: 4px 8px !important;
                font-size: 12px;
                width: 120px;
                text-align: center;
                margin-bottom: 5px;
              }

              &:hover {
                background: #031f2d;
                color: #fff;
                border-color: #031f2d;
              }
            }
          }

        }
      }
    }

  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @media(min-width: 801px) {

    &.sticky-active {
      position: fixed;
      top: 0;
      transition: all ease 500ms;
      height: 100px;
      z-index: 10;
      box-shadow: 0 0 15px rgba(55, 55, 55, .3);
      background-color: #fff;
      animation: slideDown 0.35s ease-out;

      #open-menu img {
        filter: invert(0%);
      }

      .logoContainer img {
        filter: invert(100%);
      }

      .left-navigation nav ul li a,
      .right-navigation nav ul li a {
        color: #111;

        &:hover {
          color: rgb(150, 150, 150);
        }
      }

      .left-navigation nav ul li.btn-nav a,
      .right-navigation nav ul li.btn-nav a {
        color: #111;
        border: 1px solid #111;

        img {
          filter: invert(75%);
        }

        &:hover {
          color: #fff;
          border: 1px solid #031f2d;

          img {
            filter: invert(0%);
          }
        }
      }


    }

  }

  @media (min-width:768px) and (max-width:960px) {
    .logoContainer img {
      max-width: 130px;
    }
  }

  @media(max-width: 800px) {

    &.sticky-active {
      position: relative;
      top: 0 !important;
      background-color: #fff;
      animation: slideDown 0.35s ease-out;
    }

    .logoContainer img {
      max-width: 80px;
      max-height: 85px;
      margin: 0 0 0 auto;
    }

    .left-navigation,
    .right-navigation {
      ul {
        li a {
          font-size: 14px !important;
        }

        li.btn-nav a {
          padding: 5px 12px !important;
          top: 10px;
        }
      }
    }

    .nav-secondary {
      display: none !important;
    }

    .sidebar-menu {
      ul {
        li a {
          font-size: 30px !important;
        }
      }
    }

  }

}

.admin-bar {

  header.site-header {
    top: 32px;
  }

  @media(max-width:600px) {
    header.site-header {
      top: 46px;
    }
  }

  .left-navigation .sidebar-menu>button {
    top: 45px !important;
  }

  header.site-header .left-navigation .sidebar-menu {
    padding-top: 100px;
  }
}


/**************************************************/
/***************       HOMEPAGE      **************/
.wp-block-cover {
  &.home-cover {
    position: relative;

    .bottom-paragraph {
      position: absolute;
      bottom: 30px;
      text-align: center;
      width: 100%;
      left: 0;
    }
  }
}

.wp-block-group {
  max-width: 1200px;
  margin: 0 auto;

  .is-layout-constrained {
    display: block;
  }
}

.wp-block-columns {
  max-width: 1200px;
  margin: 0 auto;
}

.simple-page-wrapper {
  h1 {
    font-size: 42px;

    margin-bottom: 20px;
    line-height: 1.2;
  }

  h2 {
    font-size: 38px;

    margin-bottom: 15px;
  }

  h3 {
    font-size: 32px;

    margin-bottom: 15px;
  }

  p {

    line-height: 1.2;
  }

  ul {
    padding: 0 0 0 15px;

    li {

      margin-bottom: 5px;
    }
  }


  @media (max-width:600px) {
    .wp-block-group {
      .is-layout-constrained {
        padding: 0 15px;
      }
    }

    .wp-block-columns {
      padding: 0 15px;
    }
  }
}

.inquiry-form {
  padding: 50px 15px;
  font-size: 15px;

  h3 {
    text-align: center;
    margin-bottom: 50px;
  }

  .form-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .form-column {
      margin-bottom: 5px;

      input,
      textarea {
        width: 100%;
        outline: 0;
        font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
        letter-spacing: 0.10em;

        &:focus {
          border-bottom-color: #e2ba4c;
        }
      }

      input {
        height: 40px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #01273a;

        &::placeholder {
          font-size: 15px;
          opacity: 1;
          text-transform: uppercase;
        }
      }

      #broker-adsource {
        font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
        color: #000;
        text-transform: uppercase;
        font-size: 15px;
        letter-spacing: 0.10em;
      }

      textarea {
        height: 50px;
        background: transparent;
        border: none;
        border-bottom: 1px solid #02293a;

        &::placeholder {
          font-size: 15px;
          text-transform: uppercase;
        }
      }

      &.submit-column {
        justify-content: center;
        display: flex;
        position: relative;
        margin-top: 30px;

        .wpcf7-submit {
          border: 1px solid #02293a;
          padding: 10px 35px 12px;
          display: inline-block;
          color: #02293a;
          width: auto;
          line-height: 1;
          height: auto;
          border-radius: 0px;
          text-transform: uppercase;
          font-size: 16px;
          cursor: pointer;
          letter-spacing: 0.10em;

          &:hover {
            background: #02293a;
            color: #fff;
          }
        }

        .wpcf7-spinner {
          position: absolute;
          left: 55%;
          top: 10px;
        }
      }
    }


    .half-size {
      width: calc(50% - 15px);
    }

    .full-size {
      width: calc(100% - 15px);
    }

    label {
      font-size: 15px;
      text-transform: uppercase;
    }
  }

}

.wpcf7-response-output {
  max-width: 800px;
  margin: 15px auto !important;
}

/**************************************************/
/*********        MISC POST/PAGES      ************/

.entry-header {
  max-width: 100%;
  padding: 30px 15px 30px;
  margin: 15px auto 30px;
  position: relative;

  h1 {
    color: #d8b54e;
    letter-spacing: 1px;
    font-size: 46px;

    text-align: center;
  }

  @media(max-width:800px) {
    h1 {
      font-size: 30px;
    }
  }
}

.entry-header:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.45);
}

.entry-header-bg.has-image {
  padding: 85px 0 85px;
  margin: 0 !important;
  background-size: cover !important;

  @media(max-width: 767px) {
    padding: 30px 0 30px;
  }
}

.entry-header-bg img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.entry-header-bg .entry-title {
  padding: 0;
  z-index: 1;
  position: relative;
}

.entry-header-bg.has-image .entry-title {
  color: #ffffff;
  z-index: 2;
  text-shadow: 0px 0px 15px #02293a;
  mix-blend-mode: hard-light;
}

.entry-content {
  max-width: 1200px;
  margin: 0 auto 50px;
  padding: 0 15px;

  p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.4;
  }

  a {
    text-decoration: underline;
    color: #d8b54e;

    &.btn-style-one,
    &.btn-style-two {
      color: #FFF;
      text-decoration: none;
      padding: 10px 20px;
    }
  }

  h1 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  ul {
    padding: 0 0 0 15px;
    display: block;
    margin: 0 0 20px;

    li {
      line-height: 1.8;
    }
  }

  strong {
    font-weight: 600;
  }

  em,
  i {
    font-style: italic;
    font-weight: 400;
  }

  blockquote {
    background: #f1e6df;
    border-left: 8px solid #d8b54e;
    margin: 30px 30px 30px 0;
    padding: 15px 25px 15px 30px;
    border-radius: 3px;

    strong {
      font-weight: normal;
      font-size: 20px;
      display: inline-block;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 10px;
      font-size: 16px;
    }

    cite {
      margin-bottom: 20px;
      font-style: normal;
    }

    em {
      margin-top: 15px;
      display: inline-block;
      font-size: 13px;
    }
  }

  .post-edit-link {
    color: #333333;
    text-decoration: underline;
  }

  .aligncenter,
  .alignleft,
  .alignright {
    display: block;
    padding: 0;
  }

  .aligncenter {
    float: none;
    margin: .5em auto 1em;
  }

  .alignright {
    float: right;
    margin: .5em 0 1em 1em;
  }

  .alignleft {
    float: left;
    margin: .5em 1em 1em 0;
  }

  .wp-caption {
    padding: 5px 0;
    border: 1px solid #555;
    background: #444;
    text-align: center;
  }

  .wp-caption img {
    display: inline;
  }

  .wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
  }

  code {
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  }
}

.entry-footer {
  max-width: 1200px;
  margin: 0 auto 50px;
  padding: 0 15px;
}

.error404 {
  .entry-title {
    max-width: 1200px;
    margin: 0 auto 50px;
    padding: 0 15px;
    font-size: 50px;
  }

  main.content {
    margin-top: 50px;
  }
}

.archive {
  .entry-header {
    text-align: center;
  }
}


/**************************************************/
/***************       FOOTER      ****************/

.footer-form {
  width: 100%;
  padding: 15px 0 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  letter-spacing: 0.05em;
  margin: 15px 0 0;
  line-height: 1.6;

  .footer-form-heading {
    border-bottom: 1px solid #000;
    padding-top: 15px;
    padding-bottom: 30px;
    text-align: center;
  }

  h3 {
    font-size: 2.2vw;
  }

  @media(max-width:800px) {
    h3 {
      font-size: 8vw;
    }

    .footer-form-heading {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

footer.site-footer {
  background: #031f2d;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.05em;
  padding-bottom: 100px;

  .wrap {
    width: 100%;
    max-width: 100%;

    >p {
      display: none;
    }

    padding: 0 15px;
    margin: 0 auto;
  }

  .footer-sections {
    padding: 30px 0 0;
    width: 100%;
    max-width: 1230px;
    margin: 0 auto;
  }

  .footer-right-text {
    justify-content: right;
    text-align: right;
    line-height: 1.4;
  }

  .top-section {
    max-width: 860px;
    margin: 0 auto 30px;
    text-align: center;

    .widget-col {
      img {
        margin: 0 auto 30px;
        max-width: 250px !important;
      }
    }

    .footer-message {
      padding: 15px 0;
      font-size: 14px;
      font-weight: normal;

      .inside {
        max-width: 600px;
        margin: 0 auto;
        color: #fff;
      }
    }
  }

  .widget-wrapper {
    max-width: 1100px;
    margin: 0 auto;
  }

  .all-widgets {
    display: flex;
    justify-content: space-between;

    .widget-column {

      h3 {
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: #e2ba4c;
      }

      >div {
        margin-bottom: 30px;
      }

      small {
        font-size: 12px;
        line-height: 1;
        margin-bottom: 5px;
      }

      p {
        margin-bottom: 5px;
        color: #fff;
        line-height: 1;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #e2ba4c;
          text-decoration: underline dotted;
        }
      }

      &:nth-child(2) {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: end;
      }
    }

    .footer-logo {
      width: 30%;

      img {
        max-width: 150px;
        filter: brightness(5000%);
      }
    }

    .footer_social_Icons {
      display: flex;
      justify-content: right;
      align-items: flex-end;
      gap: 15px;

      .footer__socialIcon {
        width: 30px;
        height: 30px;
        display: inline-block;

        svg path {
          fill: #01273a;
        }
      }
    }



    @media(max-width: 600px) {
      & {
        flex-wrap: wrap;
        gap: 15px 0;
      }

      .widget-column:nth-child(1) {
        width: 50%;
      }

      .widget-column:nth-child(2) {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: end;
      }

      .widget-column:nth-child(3) {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  .footer-credentials {
    width: 100%;
    margin: 15px 0 0;
    text-align: center;
    color: #fff;
    padding: 15px 0;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #d8b54e;
        text-decoration: underline dotted !important;
      }
    }
  }

  .mc4wp-form {
    input[type=email] {
      border: none;
      border-bottom: 2px solid #000;
      width: 100%;
      height: 32px;
      margin-bottom: 5px;

      &::placeholder {
        opacity: 1;
        color: #000;
      }
    }


    input[type=submit] {
      margin: 15px 0 0;
      border: none;
      padding: 7px 20px;
      color: #FFF;
      background: #000;
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      border-radius: 15px;
      font-weight: 600;

      &:hover {
        background: #C9212A;
      }
    }

  }

  .custom-html-widget {
    img {
      transition: transform ease 200ms;
      margin-bottom: 12px;
      max-width: 180px;

      &:hover {
        transform: scale(1.1);
        transition: transform ease 200ms;
      }
    }

    strong {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 10px;
      line-height: 1;
      display: flex;
      align-items: center;

      img {
        max-width: 18px;
        margin-right: 5px;
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
        color: #C8212A;
      }
    }

    .social-footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 20px 0 0;

      li {
        list-style: none;

        span {
          display: flex;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #fff;
          align-items: center;
        }

        img {
          max-width: 25px;
          margin: 0;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }

    table {
      width: 100%;
      border: 0;

      tr td:nth-child(2) {
        text-align: right;
      }

    }
  }

  ul.menu {
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 15px;

      a {
        text-decoration: none;
        color: #02293a;
        transition: all ease 200ms;
        transform: translateY(0);
        position: relative;
        display: inline-block;

        &:hover {
          color: #d8b54e;
          transform: translateX(3px);
          transition: all ease 200ms;
        }
      }
    }
  }

  @media (min-width:600px) and (max-width:960px) {
    .all-widgets {
      flex-wrap: wrap;
    }
  }

  @media(max-width:800px) {
    .footer-logo {
      order: 1;
      width: 100% !important;
      margin-bottom: 20px !important;
      justify-content: center;

      img {
        margin: 0 auto;
        display: block;
      }
    }

    .left-column {
      order: 2;
      width: 50% !important;
    }

    .right-column {
      order: 3;
      width: 50% !important;

      .footer-right-text {
        width: 100%;
      }
    }
  }

}


.site-loader {
  background-color: #0f1e2c;
  opacity: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  visibility: visible;
  overflow: hidden;
  width: 100vw;

  .loader-content {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;

    svg {
      width: 125px;
    }
  }

  &.site-loaded {
    transition: all 500ms ease;
    opacity: 0;
    visibility: hidden;
  }

  path.g,
  path.ale {
    fill: transparent;
    stroke: white;
    stroke-width: 3px;
  }

  path.g {
    stroke-dasharray: 840;
    stroke-dashoffset: 840;
    animation: fillOne 2.5s ease forwards .5s;
  }

  path.ale {
    stroke-dasharray: 1070;
    stroke-dashoffset: 1070;
    animation: fillTwo 2.5s ease forwards .5s;
  }

  g.text {
    transform: translateY(15px);
    animation: text 1s ease forwards 3.25s;
    opacity: 0;
  }

  @keyframes fillOne {
    0% {
      stroke-dasharray: 840;
      stroke-dashoffset: 840;
      stroke-width: 3px;
      fill: transparent;
    }

    75% {
      stroke-dasharray: 840;
      stroke-dashoffset: 0;
      stroke-width: 3px;
      fill: transparent;
    }

    100% {
      stroke-dasharray: 840;
      stroke-dashoffset: 0;
      stroke-width: 0px;
      fill: white;
    }
  }

  @keyframes fillTwo {
    0% {
      stroke-dasharray: 1070;
      stroke-dashoffset: 1070;
      stroke-width: 3px;
      fill: transparent;
    }

    75% {
      stroke-dasharray: 1070;
      stroke-dashoffset: 0;
      stroke-width: 3px;
      fill: transparent;
    }

    100% {
      stroke-dasharray: 1070;
      stroke-dashoffset: 0;
      stroke-width: 0px;
      fill: white;
    }
  }

  @keyframes text {
    from {
      transform: translateY(15px);
      opacity: 0;
    }

    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

}

.splide {
  .slide {
    padding: 15px 0;
    margin: 15px 0;
    display: flex;
    width: 100vw;
    position: relative;

    .image-container {
      width: 60%;
      position: relative;
      padding: 0;

      img {
        width: 100%;
        height: auto;
        max-height: 900px;
        object-fit: contain;
      }

      @media(min-width:768px) {

        &.right-side {
          padding-left: 15px;
        }

        &.left-side {
          padding-right: 15px;
        }

      }

    }

    .text-container {
      width: 40%;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      text-align: justify;
      padding: 0 15px;
      font-size: 18px;
      line-height: 1.4;

      @media(min-width:768px) {

        &.right-side {
          padding-left: 50px;
        }

        &.left-side {
          padding-right: 50px;
        }

      }

      >div {
        max-width: 450px;
        margin: 0 auto;

        p {
          line-height: 1.4;
          font-size: 16px;
          text-align: left;
          letter-spacing: 0.05em;
        }

        h3 {
          font-size: 5vw;
          line-height: 1;
          text-align: left;
          margin-bottom: 15px;
        }
      }
    }

    @media(max-width:767px) {

      & {
        flex-direction: column;
        padding-top: 0px;
      }

      .image-container {
        order: 1;
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;

        img {
          max-width: 400px;
          margin: 0 auto;
        }

      }

      .text-container {
        order: 2;
        width: 100%;
        padding: 0 15px;

        div>h3 {
          font-size: 10vw;
        }
      }
    }

  }

  .splide__arrow {
    background: transparent;

    img {
      max-width: 32px;
    }
  }

  .splide__arrow svg {
    fill: #000;
    height: 2em;
    width: 2em;
  }

}

.full-width-image {
  img {
    width: 100%;
  }
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

@media(max-width:800px) {
  .full-wrap .wp-block-columns.home-sharing-list {
    flex-wrap: wrap !important;
    padding: 50px 15px !important;

    >.wp-block-column {
      flex-basis: 45% !important;
    }
  }

  p.has-x-large-font-size,
  h2.has-x-large-font-size,
  h3.has-x-large-font-size {
    font-size: 4vw !important;

  }

  p.has-large-font-size,
  h2.has-large-font-size,
  h3.has-large-font-size {
    font-size: 3vw !important;
  }
}

.location-list {
  h3 {
    margin-top: 30px !important;
  }
}

p.has-x-large-font-size {
  font-size: 6vw !important;
  font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

p.has-large-font-size {
  font-size: 46px !important;
  font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

p.has-medium-font-size {
  font-size: 36px !important;
  font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

p.has-small-font-size {
  font-size: 18px !important;
  letter-spacing: 0.20em;
}

h1,
h2,
h3 {
  font-family: 'Helvetica Now Display', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

.intro-paragraph {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  letter-spacing: 0.05em;
}

.white-column {
  padding: 30px;
  max-width: 450px;

  ul,
  ol {
    margin: 35px 0 30px;
    padding-left: 20px;

    li {
      margin-bottom: 15px;
      letter-spacing: 1px;
    }
  }
}

.as-locals-distance {
  max-width: 1200px;
  margin: 50px auto;
  padding: 0 15px;

  ul {
    display: flex;
    padding-left: 0;

    li {
      width: 16.6666%;
      list-style: none;

      >p {
        font-size: 26px;
        margin-bottom: 10px;
      }

      em {
        font-size: 18px;
      }
    }
  }

  @media(max-width:800px) {
    & {

      ul {
        flex-wrap: wrap;

        li {
          width: 50%;
          margin-bottom: 25px;

          >p {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.wp-block-button__link {
  font-size: 16px;
  font-family: "Helvetica Now Display", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 0.3em;
  padding: 8px 15px;
  font-size: 14px;
  border-width: 1px !important;
  border-radius: 0 !important;

  &:hover {
    background-color: #031f2d !important;
    border-color: #031f2d !important;
    color: #FFF !important;
  }
}

.click-platforms {
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.2em;
  color: #111;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline dotted !important;
  }
}

.full-width-bordered {
  padding: 80px 15px;
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
}

.wp-block-eedee-block-gutenslider .eedee-gutenslider-nav,
.wp-block-eedee-block-gutenslider .eedee-gutenslider-nav svg {
  height: 50px !important;
  width: 50px !important;
}

strong {
  font-weight: 600;
}

.list-of-platforms {
  display: none;
  max-width: 1200px;
  padding: 30px 15px 15px;
  margin: 30px auto 15px;

  &.display-active {
    display: flex;
  }

  ul {
    width: 25%;

    li {
      list-style: disc;
      letter-spacing: 0.06em;
      margin-bottom: 10px;
    }
  }

  @media(max-width:800px) {

    & {
      flex-wrap: wrap;

      ul {
        width: 50%;
        margin-bottom: 40px;
      }
    }
  }
}


@media(max-width:800px) {

  p.has-x-large-font-size {
    font-size: 14vw !important;
  }

  .full-wrap .wp-block-cover {
    min-height: 65vh !important;
  }

  .reverse-order-mobile {
    flex-direction: column-reverse;
  }

  p.has-large-font-size {
    font-size: 22px !important;

    br {
      display: none;
    }
  }

  p.has-medium-font-size {
    font-size: 20px !important;

    br {
      display: none;
    }
  }

  .footer-form h3 {
    font-size: 26px;
  }

  p.has-small-font-size {
    font-size: 14px !important;
    letter-spacing: .15em !important;
  }
}

/**************************************************/
/*********            GALLERY          ************/

.gallery-tabs {

  .tabs-panel {
    width: 100%;
  }

  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    padding: 15px 15px 10px;
  }

  nav {
    width: 100%;
  }

  .gallery-tab-nav {
    padding: 0 0 15px;
    display: flex;
    gap: 12px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    li {
      list-style: none;

      a {
        padding: 10px 20px;
        line-height: 1;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        background: transparent;
        color: #081f2c;
        transition: all ease 250ms;
        white-space: nowrap;
        text-transform: uppercase;
        letter-spacing: 1px;
        border: 1px solid #081f2c;

        &:hover {
          background: #081f2c;
          color: #fff;
          transition: all ease 250ms;
          border-color: #081f2c;
        }

        &.btn-active {
          background: #081f2c;
          transition: all ease 250ms;
          color: #fff;

          &:hover {
            background: #0b2b3e;
            color: #FFF;
            transition: all ease 250ms;
          }
        }
      }
    }
  }

  .tabs-panel {
    position: relative;

    .tab {
      opacity: 0;
      visibility: hidden;

      z-index: -1;
      padding: 15px 0;
      transition: opacity ease-out 300ms;
      position: absolute;
      top: 0;
      left: 0;

      &.tab-active {
        opacity: 1;
        visibility: visible;
        z-index: 1;
        position: relative;
        transition: opacity ease 300ms;
      }

      ul {
        padding: 0;
        display: flex;
        margin: 0 -10px;
        flex-wrap: wrap;
        min-height: 595px;

        li {
          width: 25%;
          list-style: none;
          padding: 10px;

          figure {
            overflow: hidden;
          }

          img {
            width: 100%;
            max-width: 277px;
            height: auto;
            display: block;
            background-color: #b1b1b9;
            transition: transform ease-out 200ms;
            filter: blur(4px);
            aspect-ratio: 1;

            &.fadeIn {
              filter: blur(0px);
            }

            &:hover {
              transform: scale(1.1);
              transition: transform ease-in 200ms;
            }
          }

          @media (max-width:767px) {
            & {
              width: 50%;
            }
          }

        }
      }
    }
  }
}


.relative-column {
  position: relative;
}

#map {
  height: 660px;
}

.marker {
  background: #031f2d;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 14px;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  color: #000;
}


.site-header.gt-v{
  overflow: visible;
}

/*---landing page---*/
.page-template-pt-landing-page{
  .footer-credentials{
    display: none;
  }

  footer.site-footer{
    padding-top: 25px;
    padding-bottom: 15px;
  }
}
.ld-header{
  background: #031f2d;
  padding: 20px 15px;
  &-logo{
    max-width: 110px;
    margin: auto;
    img{
      width: 100%;
    }
  }
}

.hero{
  border-bottom: 2px solid #031f2d;
  line-height: 0;
  &-img{
    img{
      width: 100%;
      object-fit: cover;
      max-height: calc(100vh - 150px);
    }
  }
}

.ld-contact{
  border-top: none;
  padding-top: 0;
  margin-top: 0;
  
  .inquiry-form{
    > h3{
      display: none;
    }
  }

  .contact-header{
    text-align: center;
    padding: 25px 15px;
    
    border-bottom: 2px solid #031f2d;
    @media (min-width: 768px) {
      padding: 30px 15px;
    }
    @media (min-width: 992px) {
      padding: 45px 15px;
    }
    @media (min-width: 1200px) {
      padding: 50px 15px;
    }
    > h2{
      font-size: 26px;
      margin-bottom: 8px;
      line-height: 1.1;
      font-weight: 400;
      @media (min-width: 992px) {
        font-size: 44px;
        margin-bottom: 5px;
      }

      span{
        font-weight: 400;
        font-style: italic;
        font-family: "Prata", serif;
      }
    }

    > h3{
      text-transform: uppercase;
      font-size: 17px;
      margin-bottom: 0;
      line-height: 1.1;

      @media (min-width: 992px) {
        font-size: 25px;
      }
    }
  }
}


.page-template-pt-thankyou-page{
    // .site-header{
    //   position: static;
    //   background-color: #031f2d;
    // }
    .page-content{
      padding-top: 150px;
      padding-bottom: 150px;
      h1{
        max-width: 100%;
        padding: 30px 15px 30px;
        margin: 0;
        position: relative;
        color: #d8b54e;
        letter-spacing: 1px;
        font-size: 46px;
        text-align: center;
        max-width: 650px;
        margin: 0 auto;
      }
      p{
        max-width: 1200px;
        margin: 0 auto 60px;
        padding: 0 15px;
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 1.4;
        max-width: 650px;
      }
    }
}